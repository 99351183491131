import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { fetchApi } from "../../utils/Connections";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const data = [
  {
    name: "Page A",
    uv: 4000,
    "Number of Posts": 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    "Number of Posts": 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    "Number of Posts": 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    "Number of Posts": 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    "Number of Posts": 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    "Number of Posts": 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    "Number of Posts": 4300,
    amt: 2100,
  },
];

const CustomizedLabel = ({ x, y, stroke, value }: any) => {
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
};

const Analytics = () => {
  const { t } = useTranslation();

  useEffect(() => {}, []);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const getDate = async () => {
    const result = await fetchApi(`Admins/GetAnalytics`, "GET", {});
    console.log(result);
    if (result.StatusCode === 200) {
      setPosts(result.My_Result.Posts);
      setUsers(result.My_Result.Users);
    } else {
      alert("Something went wrong");
    }
  };

  useEffect(() => {
    getDate();
  }, []);

  return (
    <>
      <div className="text-center mt-5 py-4">
        <h4>{t("Posts")}</h4>
      </div>
      <ResponsiveContainer width="100%" height="100%" className="chart-height ">
        <LineChart
          width={500}
          height={300}
          data={posts}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="MonthYear"
            height={60}
            tick={<CustomizedAxisTick />}
          />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="PostCount"
            stroke="#8884d8"
            label={<CustomizedLabel />}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>

      <div className="text-center">
        <h4>{t("Users")}</h4>
      </div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className="chart-height mt-5 py-4">
        <LineChart
          width={500}
          height={300}
          data={users}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="MonthYear"
            height={60}
            tick={<CustomizedAxisTick />}
          />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="UserCount"
            stroke="#8884d8"
            label={<CustomizedLabel />}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default Analytics;
