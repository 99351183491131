import React, { useEffect, useState, useRef } from "react";
import UserCard from "../../components/user-card/UserCard";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { fetchApi, ADMIN_SOCKET_URL } from "../../utils/Connections";
import Spinner from "../../components/spinner/Spinner";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

import io from "socket.io-client";

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUsers: (posts: any) =>
      dispatch({ type: "UPDATE_USERS", payload: posts }),
    resetUsers: () => dispatch({ type: "RESET_USERS" }),
  };
};

const AllUsers = (props: any) => {
  const client = useRef<any>(null);

  const { state } = props;
  const [users, setUsers] = useState<[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<[]>([]);
  const currentLanguageCode = Cookies.get("i18next") || "en";

  const updateUsers = props.updateUsers;

  let offset = 0;
  let [Deleted, setDeleted] = useState(false);
  useEffect(() => {
    if (state.Users.length === 0) {
      GetUsers();
    } else {
      setUsers(state.Users);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setUsers(state.Users);
  }, [state.Users]);

  const GetUsers = async (isDeleted: boolean = Deleted) => {
    setLoading(true);
    setHasMore(true);
    const result = await fetchApi(
      `/Admins/GetAllUsers?Offset=${offset}&Banned=${isDeleted}`,
      "GET",
      {}
    );
    console.log(result);
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0 && offset !== 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let u = [];
      if (offset === 0) {
        u = result.My_Result;
      } else {
        u = [...users, ...result.My_Result];
      }
      updateUsers(u);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setLoading(true);
    offset += 1;
    await GetUsers();
  };

  const getBannedUsers = () => {
    setLoading(true);
    let isDeleted = !Deleted;
    console.log(isDeleted);
    setDeleted(!Deleted);
    offset = 0;
    setUsers([]);
    GetUsers(isDeleted);
  };

  useEffect(() => {
    client.current = io(ADMIN_SOCKET_URL);
    client.current?.on("connect", () => {
      console.warn("connected");
    });
    return () => {
      client.current.disconnect();
    };
  }, []);

  useEffect(() => {
    client.current?.emit(
      "Search-Users",
      {
        "x-access-token": state.token,
        Text: search,
      },
      (message: any) => {
        console.log("Read Post");
        console.log(message);
        setSearchResults(message.Data);
      }
    );
  }, [search]);
  // const searchUsers = async () => {
  //   const result = await fetchApi(
  //     `/Admins/GetAllUsers?Offset=0&Deleted=true`,
  //     "GET",
  //     {}
  //   );
  //   if (result.StatusCode === 200) {
  //     console.log(result.My_Result);
  //   } else {
  //     console.log(result);
  //   }
  // };

  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <div className="py-4">
      <div className="text-center d-flex flex-column justify-content-center align-items-center mt-5">
        <h3 className=" asm-color-primary">{t("All Users")}</h3>
        <div className="d-flex w-100 px-3 dropdown">
          <div className="w-100">
            <input
              type="text"
              className={`form-control ${
                showDropdown ? "rounded-0 rounded-top" : ""
              } dropdown-toggle`}
              placeholder={`${t("Search")}...`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                setTimeout(() => setShowDropdown(false), 100);
              }}
            />
            <div
              className={`dropdown-menu dropdown-width rounded-0 rounded-bottom border-top-0 ${
                showDropdown ? "show" : ""
              }`}
              style={{
                maxHeight: "30rem",
                overflowY: "scroll",
              }}>
              {searchResults.map((user: any) => {
                return (
                  <div
                    key={user.IdUser}
                    className="dropdown-item"
                    onClick={() => {
                      navigate(`/Dashboard/User?id=${user.IdUser}`);
                    }}>
                    {user.UserName} - {t("Posts")}: {user.PostCount}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 px-3 mt-2">
          <button
            className="btn asm-btn-bg-color-primary text-white"
            onClick={getBannedUsers}>
            {t(!Deleted ? "BannedUsers" : "All Users")}
          </button>
        </div>
      </div>
      <div className="fluid-container mx-3">
        <div className="row">
          {users.map((user: any) => (
            <div
              className=" col-xl-4 col-lg-6 col-md-6 col-12 mt-3"
              key={user.IdUser}>
              <UserCard _user={user}></UserCard>
            </div>
          ))}
        </div>
        <div>
          {loading && <Spinner />}
          {users.length === 0 && !loading && (
            <div className="text-center text-muted mt-3">{t("NoUsers")}</div>
          )}
          {hasMore && !loading && users.length !== 0 && (
            <button
              className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
              onClick={loadMore}>
              {t("LoadMore")}
            </button>
          )}
          {!hasMore && users.length !== 0 && (
            <div className="text-center text-muted mt-3">
              {t("NoMoreUsers")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
