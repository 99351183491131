// src/serviceWorkerRegistration.js

export function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registration successful with scope: ', registration.scope);
        }, (err) => {
          console.log('Service Worker registration failed: ', err);
        });
    }
  }
  