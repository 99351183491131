import React, { useState, useEffect, useRef } from "react";

import { fetchApi } from "../../utils/Connections";
import { use } from "i18next";
import moment from "moment";

import { useTranslation } from "react-i18next";

const TicketResponce = (props: any) => {
  const [ticket, setTicket] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [Messages, setMessages] = useState<[]>([]);
  const [open, setOpen] = useState(false);

  const [Message, setMessage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    setMessages([]);
    setTicket(props.ticket);
    if (props.ticket !== null) {
      getTicketMessages();
      return;
    }
  }, [props.ticket]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const getTicketMessages = async () => {
    const result = await fetchApi(
      `Support/GetTicketMessages?IdTicket=${props.ticket.IdTicket}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      setMessages(result.My_Result.Emails);
    } else {
      alert("Something went wrong");
    }
  };

  const sendMessage = async () => {
    const result = await fetchApi(`Support/InsertTicketResponse`, "POST", {
      IdTicket: ticket.IdTicket,
      IdAdmin: sessionStorage.getItem("id"),
      Message: Message,
    });
    if (result.StatusCode === 200) {
      let m: any = [
        ...Messages,
        { Message: Message, Sent: true, Date: Date.now() },
      ];
      setMessages(m);
      setMessage("");
    } else {
      alert("Something went wrong");
    }
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [Messages]);

  return (
    <>
      <div
        className={`backdrop ${open && "show"}`}
        onClick={props.CloseModal}></div>
      <div className={`adminSidebar ${open ? "show" : ""}`}>
        <div>
          <div>
            {ticket !== null && (
              <div className="d-flex justify-content-between align-items-center border-bottom px-2 asm-bg-color-primary text-white">
                <div className="d-flex align-items-center">
                  <img
                    src={ticket.ProfilePicture}
                    alt=""
                    className="rounded-circle my-2 mx-1 postImages border border-2 border-white "></img>
                  <span className="fw-bold">{ticket.UserName}</span>
                </div>
                <div className="cursor-pointer" onClick={props.CloseModal}>
                  <i className="bi bi-x text-size-lg"></i>
                </div>
              </div>
            )}
            <div className="messages px-2">
              {ticket !== null && (
                <div className={`d-flex justify-content-start`}>
                  <div className={`shadow my-2 p-2 rounded message bg-white`}>
                    <div className="">{ticket.Message}</div>
                    <div className="text-size-sm text-mute text-end">
                      {moment(ticket.DateCreated).format("YY/MM/DD - hh:mm a")}
                    </div>
                  </div>
                </div>
              )}

              {Messages.map((message: any) => (
                <div
                  className={`d-flex justify-content-${
                    message.Sent ? "end" : "start"
                  }`}>
                  <div
                    className={`shadow my-2 p-2 rounded message ${
                      message.Sent ? "asm-bg-faded-primary" : "bg-white"
                    }`}>
                    <div className="">{message.Message}</div>
                    <div className="text-size-sm text-mute text-end">
                      {moment(message.Date).format("YY/MM/DD - hh:mm a")}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="asm-bg-color-primary p-3 send d-flex align-items-center">
              <button
                title={t("Refresh Messages")}
                className="btn bg-white asm-btn-color-primary mx-2 border border-2 border-white bi bi-arrow-repeat me-2"
                onClick={getTicketMessages}></button>
              <input
                type="text"
                className="form-control border border-2 border-white"
                placeholder="Write a message..."
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                className="btn bg-white asm-btn-color-primary mx-2 border border-2 border-white"
                onClick={sendMessage}>
                {t("Send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketResponce;
