// src/push-notification.js

import { messaging, getToken } from './firebase';

export const requestForToken = async () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Token received:', currentToken);
        // Perform necessary operation with the token
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // Show permission UI.
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};


// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
// });
