import React, { useState } from "react";
import PostComment from "../postComment/PostComment";
import Post from "../post/Post";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function PostCard(props: any) {
  const state = props;
  const [post, setPost] = useState(state.post);
  const { t } = useTranslation();

  const deleteComment = (idComment: number) => {
    let index = state.post.Comments.findIndex(
      (c: any) => c.IdComment === idComment
    );
    let comments = [...state.post.Comments];
    comments.splice(index, 1);
    let post = { ...state.post };
    post.Comments = comments;
    setPost(post);
  };

  return (
    <div
      className={`card shadow ${
        state.post.Reported ? "border border-danger" : "border-0"
      }`}>
      <div className="card-body pt-1">
        <Post post={post} index={state.index}></Post>
        {state.post.Comments.length !== 0 ? (
          <div className="border-top mt-2">
            {post.Comments.map((comment: any) => (
              <PostComment
                deleteComment={() => deleteComment(comment.IdComment)}
                key={comment.IdComment}
                comment={comment}></PostComment>
            ))}
          </div>
        ) : (
          <div className="text-center text-muted mt-3">{t("NoComments")}</div>
        )}
      </div>
    </div>
  );
}
