import React, { useState } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import Modal from "../modal/Modal";
import Spinner from "../spinner/Spinner";
import { useTranslation } from "react-i18next";

import { fetchApi } from "../../utils/Connections";

const PostComment = (props: any) => {
  const { t } = useTranslation();
  const state = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteComment = async () => {
    setLoading(true);
    const result = await fetchApi(`/Admins/DeleteComment`, "DELETE", {
      IdComment: state.comment.IdComment,
    });
    console.log(result);
    if (result.StatusCode === 200) {
      props.deleteComment(state.comment.IdComment);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const navigate = useNavigate();
  return (
    <>
      <div
        className={
          state.comment.Reported ? "border border-danger rounded p-2 my-1" : ""
        }>
        <div className={`d-flex justify-content-between mt-2 `}>
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => {
              navigate(`/Dashboard/User?id=${state.comment.IdUser}`);
            }}>
            <img
              src={state.comment.ProfilePicture}
              alt="avatar"
              className="rounded-circle mx-2 postImages"
            />
            <div>
              <div className="fw-bold">
                {state.comment.UserName}
                {state.comment.Deleted && " - Deleted"}
              </div>
              <div className="text-muted">
                {moment(state.comment.DateCreated).format("YY/MM/DD - hh:mm a")}
              </div>
            </div>
          </div>
          {!state.comment.Deleted && (
            <div
              className="d-flex align-items-center"
              onClick={() => setDeleteModal(true)}>
              <i className="bi bi-trash cursor-pointer text-danger"></i>
            </div>
          )}
        </div>
        <div className=" py-2 ">
          <div>{state.comment.Text}</div>
        </div>
      </div>
      <Modal
        title={t("DCModal")}
        open={deleteModal}
        CloseModal={() => setDeleteModal(false)}>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="text-mute text-size-sm text-center ">
            {t("DeleteCommentMSG")}
          </div>
          {loading && <Spinner />}

          <button
            className="btn asm-btn-bg-color-primary text-white mt-3"
            onClick={() => deleteComment()}>
            {t("Delete")}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default PostComment;
