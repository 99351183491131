import React, { useState, useEffect } from "react";
import { fetchApi } from "../../utils/Connections";
import TicketCard from "../../components/ticketCard/TicketCard";
import TicketResponce from "../../components/ticketResponce/TicketResponce";
import Spinner from "../../components/spinner/Spinner";

import { useTranslation } from "react-i18next";

export default function Ticket() {
  const [show, setShow] = useState(false);
  const [tickets, setTickets] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [chosenTicket, setChosenTicket] = useState<any>(null);

  const { t } = useTranslation();

  let offset = 0;

  useEffect(() => {
    getTicket();
  }, []);

  const getTicket = async () => {
    setLoading(true);
    const result = await fetchApi(
      `Support/GetTickets?Offset=${offset}`,
      "GET",
      {}
    );
    console.log(result);
    if (result.StatusCode === 200) {
      if (result.My_Result.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      let t = [];
      if (tickets === null) {
        t = result.My_Result;
      } else {
        t = [...tickets, ...result.My_Result];
      }
      setTickets(t);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const loadMore = () => {
    setLoading(true);
    offset += 1;
    getTicket();
  };

  return (
    <div className="py-4">
      <div className="text-center d-flex justify-content-center align-items-center mt-5 ">
        <h3 className=" asm-color-primary ">{t("Tickets")}</h3>
      </div>
      <div className="fluid-container mx-3">
        <div className="row">
          {tickets !== null &&
            tickets.map((ticket: any) => (
              <div className="col-lg-6 col-12" key={ticket.IdTicket}>
                <TicketCard
                  ticket={ticket}
                  openModal={() => {
                    setShow(true);
                    setChosenTicket(ticket);
                  }}
                />
              </div>
            ))}
        </div>
        {loading && <Spinner />}
        {tickets !== null && tickets.length === 0 && !loading && (
          <div className="text-center text-muted mt-3">{t("NoTickets")}</div>
        )}
        {tickets !== null && hasMore && !loading && tickets.length !== 0 && (
          <button
            className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
            onClick={loadMore}>
            {t("LoadMore")}
          </button>
        )}
        {!hasMore && tickets !== null && tickets.length !== 0 && (
          <div className="text-center text-muted mt-3">
            {t("NoMoreTickets")}
          </div>
        )}
      </div>
      <TicketResponce
        ticket={chosenTicket}
        open={show}
        CloseModal={() => setShow(false)}
      />
    </div>
  );
}
