import React from "react";
import MultiPost from "../../components/multiPost/MultiPost";
import { useTranslation } from "react-i18next";

export default function AllPosts() {
  const { t } = useTranslation();
  return (
    <div className="py-4">
      <div className="text-center d-flex justify-content-center align-items-center mt-5 ">
        <h3 className=" asm-color-primary ">{t("All Posts")}</h3>
      </div>
      <MultiPost switchDeleted={true}></MultiPost>
    </div>
  );
}
