import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./screens/dashboard/Dashboard";
import Login from "./screens/login/Login";
import "./App.css";
import AllPosts from "./screens/allPosts/AllPosts";
import AllUsers from "./screens/allUsers/AllUsers";
import PostScreen from "./screens/postScreen/PostScreen";
import UserScreen from "./screens/userScreen/UserScreen";
import ReportScreen from "./screens/reportScreen/ReportScreen";
import Settings from "./screens/settings/Settings";
import Ticket from "./screens/ticket/Ticket";
import Analytics from "./screens/analytics/Analytics";
import UsersAndPermissions from "./screens/UsersAndPermissions/UsersAndPermissions";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { messaging, getToken } from './firebase';// Initialize an agent at application startup.
import { requestForToken } from "./push-notification";

// Call the function wherever you need the device ID

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const App = (props: any) => {
  const { state } = props;
  const [LoggedIn, updateLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      updateLoggedIn(true);
    }
  }, [state]);

  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    const askForNotificationPermission = async () => {
      try {
        const token = await requestForToken();
        console.log("token: ", token);
      } catch (error) {
        console.error(error);
      }
    }
    askForNotificationPermission();
  }, []);

  // useEffect(() => {
  //   if (notification?.title) {
  //     notify();
  //   }
  // }, [notification]);

  // onMessageListener()
  //   .then((payload: any) => {
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //   })
  //   .catch((err: any) => console.log("failed: ", err));
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/Dashboard"
        element={LoggedIn ? <Dashboard /> : <Navigate to="/" replace />}>
        <Route
          path="/Dashboard"
          element={<Navigate to="/Dashboard/AllPosts" replace />}
        />
        <Route path="/Dashboard/AllPosts" element={<AllPosts />} />
        <Route path="/Dashboard/AllUsers" element={<AllUsers />} />
        <Route path="/Dashboard/Post" element={<PostScreen />} />
        <Route path="/Dashboard/User" element={<UserScreen />} />
        {state.role !== 4 && (
          <Route path="/Dashboard/AllReports" element={<ReportScreen />} />
        )}
        <Route path="/Dashboard/Settings" element={<Settings />} />
        {(state.role === 1 || state.role === 2) && (
          <Route
            path="/Dashboard/Settings/UsersAndPermissions"
            element={<UsersAndPermissions />}
          />
        )}
        {state.role !== 3 && (
          <Route path="/Dashboard/Ticket" element={<Ticket />} />
        )}
        {(state.role === 1 || state.role === 2) && (
          <Route path="/Dashboard/Analytics" element={<Analytics />} />
        )}
        {/* <Route
          path="*"
          element={<Navigate to="/Dashboard/AllPosts" replace />}
        /> */}
      </Route>
      <Route path="*" element={<Login />}></Route>
    </Routes>
  );
};

export default connect(mapStateToProps)(App);
