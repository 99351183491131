import React, { useEffect } from "react";
import SideNavBar from "../../components/side-nav-bar/Side-nav-bar";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const path = window.location.pathname;
  const queryString = searchParams.toString();
  const fullPath = queryString ? `${path}?${queryString}` : path;

  useEffect(() => {
    if (path === "/Dashboard") return;
    sessionStorage.setItem("path", fullPath);
  }, [fullPath]);

  useEffect(() => {
    const savedPath = sessionStorage.getItem("path");
    if (savedPath !== null) {
      navigate(savedPath);
    }
  }, [navigate]);

  return (
    <>
      <SideNavBar key="sidenavbar"></SideNavBar>
      <div key="outlet" id="outlet">
        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
