import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';



const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "asm-app-c4750.firebaseapp.com",
    projectId: "asm-app-c4750",
    storageBucket: "asm-app-c4750.appspot.com",
    messagingSenderId: "296598075510",
    appId: "1:296598075510:web:e57f9fa4f11280d3f20757",
    measurementId: "G-PL6Y6PY09D"
};


const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export { getToken, onMessage };
