import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import Spinner from "../spinner/Spinner";
import { useTranslation } from "react-i18next";

import { fetchApi } from "../../utils/Connections";

import { useNavigate } from "react-router";

const mapStateToProps = (state: any) => {
  return { state: state };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateReports: (reports: any) =>
      dispatch({ type: "UPDATE_REPORTS", payload: reports }),
  };
};

const Report = (props: any) => {
  const [open, setOpen] = useState(false);

  const [report, setReport] = useState(props.report);
  const number = props.number;
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const ResolveReport = async () => {
    const result = await fetchApi(`Admins/UpdateSolvedReport`, "PUT", {
      IdReport: report.IdReport,
      Solved: true,
    });
    if (result.StatusCode === 200) {
      alert("Report Resolved");
      props.solveReport();
      setDeleteModal(false);
    } else {
      alert("Something went wrong");
    }
  };

  return (
    <>
      <div className="card mb-2">
        <div
          className="d-flex justify-content-between align-items-center p-2"
          onClick={() => setOpen(!open)}>
          <div
            className={`fw-bold ${
              !report.Solved ? "text-danger" : "text-success"
            }`}>
            {number}- {t("IssueReported")}! - {t(report.ReportType)} -{" "}
            {t(report.Solved ? "Resolved" : "Unresolved")}
          </div>
          <i className={`bi bi-chevron-${open ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open}>
          <div>
            <div className="p-2">
              <span className="fw-bold"> {t("IssueDescription")}: </span>
              <span className="text-muted">{t(report.Reason)}</span>

              <div>
                <span className="fw-bold">{t("ReportedBy")}: </span>
                <span
                  className="text-primary cursor-pointer"
                  onClick={() =>
                    navigate(`/Dashboard/User?id=${report.IdUser}`)
                  }>
                  {report.UserName}
                </span>
              </div>
              <div>
                <span
                  className="text-primary cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/Dashboard/Post?id=${report.IdReport}&type=Report`
                    )
                  }>
                  {t(`SeeReported${report.ReportType}`)}
                </span>
              </div>
              {!report.Solved && (
                <button
                  className="btn asm-btn-bg-color-primary text-white mt-2"
                  onClick={() => setDeleteModal(true)}>
                  {t("Resolve")}
                </button>
              )}
            </div>
          </div>
        </Collapse>
      </div>
      <Modal
        title={t("SRModal")}
        open={deleteModal}
        CloseModal={() => setDeleteModal(false)}>
        <div className="d-flex justify-content-center flex-column align-items-center">
          {loading && <Spinner />}
          <div className="d-flex ">
            <button
              className="btn asm-btn-bg-color-primary text-white mx-2"
              onClick={() => ResolveReport()}>
              {t("Resolve")}
            </button>
            <button
              className="btn btn-secondary mx-2"
              onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(Report);
