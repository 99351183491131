import React, { useEffect, useState } from "react";
import MultiPost from "../../components/multiPost/MultiPost";
import Modal from "../../components/modal/Modal";
import { useSearchParams } from "react-router-dom";

import { connect } from "react-redux";

import { fetchApi } from "../../utils/Connections";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import moment from "moment";

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const UserScreen = (props: any) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  let offset = 0;
  let deleted = false;
  let type: "User" | "Liked" | "Disliked" = "User";

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let [_banLiftTime, setBanLiftTime] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const { t } = useTranslation();

  const loadmoreType = true;

  const state = props.state;

  useEffect(() => {
    if (state.selectedUser.IdUser !== id) {
      _GetUser();
    }
  }, []);

  const _GetUser = async () => {
    setLoading(true);
    const result = await fetchApi(
      `ADMINS/GetUser?IdUser=${id}&Deleted=${deleted}&Offset=${offset}&Type=${type}`,
      "GET",
      {}
    );
    console.log(result);
    if (result.StatusCode === 200) {
      let u;
      if (offset === 0) {
        u = result.My_Result;
      } else {
        u = user;
      }

      if (offset !== 0) {
        u.UserPosts = [...u.UserPosts, ...result.My_Result.UserPosts];
      }
      if (result.My_Result.UserPosts.length === 0) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      setUser(u);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const loadMore = () => {
    if (loadmoreType) {
      offset += 1;
      _GetUser();
    } else {
      offset += 1;
      GetReports();
    }
  };

  const GetDeletedPosts = () => {
    deleted = true;
    user.UserPosts = [];
    _GetUser();
  };

  const getPost = () => {
    deleted = false;
    let u = { ...user, UserPosts: [] };
    setUser(u);
    _GetUser();
  };

  const GetLikedPosts = () => {
    type = "Liked";
    let u = { ...user, UserPosts: [] };
    setUser(u);
    getPost();
  };

  const GetDislikedPosts = () => {
    type = "Disliked";
    let u = { ...user, UserPosts: [] };
    setUser(u);
    getPost();
  };

  const GetReports = async () => {
    let u = { ...user, UserPosts: [] };
    setUser(u);
    offset = 0;
    const result = await fetchApi(
      `Admins/GetUserReports?IdUser=${id}&Offset=${offset}`,
      "GET",
      {}
    );
    console.log(result);
    if (result.StatusCode === 200) {
      let u = user;
      if (offset !== 0) {
        u.UserPosts = [...u.UserPosts, ...result.My_Result];
      } else {
        u.UserPosts = result.My_Result;
      }
      setUser(u);
    }
  };

  const handleChange = (date: any) => {
    const timestamp = Date.now();
    if (date.target.valueAsNumber < timestamp) {
      alert("Please select a future date");
      return;
    }
    setBanLiftTime(moment(date.target.valueAsNumber).toISOString());
    setShowDropdown(false);
    setOpenModal(true);
  };

  const setBanPeriod = (period: any) => {
    const banLiftTime = moment().add(period, "days").valueOf();
    setBanLiftTime(moment(banLiftTime).toISOString());
    setShowDropdown(false);
    setOpenModal(true);
  };

  const banUser = async () => {
    const id = user.IdUser;

    const body = {
      IdUser: id,
      BanRemovalDate: _banLiftTime,
    };
    const result = await fetchApi(`ADMINS/BanUser`, "DELETE", body);
    if (result.StatusCode === 200) {
      alert("User banned successfully");
      setUser({ ...user, Banned: true });
    } else {
      alert("Something went wrong");
    }
    setOpenModal(false);
  };

  const unBan = async () => {
    const id = user.IdUser;

    const body = {
      IdUser: id,
    };
    const result = await fetchApi(`Admins/UnBanUser`, "PUT", body);
    if (result.StatusCode === 200) {
      alert("User unbanned successfully");
      setUser({ ...user, Banned: false });
    } else {
      alert("Something went wrong");
    }
    setOpenModal(false);
  };

  return (
    <>
      <div className="pt-5">
        {user !== null && (
          <div className="fluid-container m-3 pb-3">
            <div className="d-flex flex-column align-items-center">
              <img
                src={user.UserStats.ProfilePicture}
                alt=""
                className="rounded-circle my-2 me-1 profileImage"></img>
              <div className="fw-bold my-2">{user.UserStats.UserName}</div>
              <div className="my-2">
                {state.role !== 4 && !user.Banned && (
                  <div className={`dropdown ${showDropdown ? "show" : ""}`}>
                    <button
                      className="btn asm-btn-bg-color-primary text-white dropdown-toggle"
                      type="button"
                      onClick={() => setShowDropdown(!showDropdown)}>
                      <span className="ms-1">{t("Ban")}</span>
                    </button>
                    <div
                      className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                      style={{
                        transform:
                          currentLanguageCode === "en"
                            ? "translateX(-60%)"
                            : "translateX(60%)",
                      }}>
                      {state.banPeriods.map((period: any) => {
                        return (
                          <div
                            key={period.duration}
                            className="dropdown-item"
                            onClick={() => {
                              setBanPeriod(period.duration);
                            }}>
                            {t(period.name)}
                          </div>
                        );
                      })}

                      <div className="dropdown-item">
                        <input
                          className="form-control"
                          type="date"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {user.Banned && (
                  <button
                    className="btn asm-btn-bg-color-primary text-white"
                    type="button"
                    onClick={() => unBan()}>
                    {t("Unban")}
                  </button>
                )}
              </div>
              <div className="d-flex ">
                <div>
                  <div
                    className=" border-2 border-black px-2 cursor-pointer text-center mb-2"
                    onClick={getPost}>
                    {user.UserStats.PostCount} {t("Posts")}
                  </div>
                  <div
                    className=" border-2 border-black px-2 cursor-pointer text-center "
                    onClick={GetDeletedPosts}>
                    {user.UserStats.PostCount} {t("DeletedPosts")}
                  </div>
                </div>

                <div>
                  <div
                    className="border-end border-start border-2 border-black px-2 cursor-pointer text-center mb-2"
                    onClick={GetLikedPosts}>
                    {user.UserStats.LikeCount} {t("Likes")}
                  </div>
                  <div
                    className="border-end border-start border-2 border-black px-2 cursor-pointer text-center"
                    onClick={GetDislikedPosts}>
                    {user.UserStats.DislikeCount} {t("Dislikes")}
                  </div>
                </div>
                <div>
                  <div
                    className=" px-2 cursor-pointer text-center mb-2"
                    onClick={GetReports}>
                    {user.UserStats.ReportCount} {t("ReportedCount")}
                  </div>
                  <div className="px-2 cursor-pointer text-center">
                    {user.UserStats.NumberOfBans} {t("Bans")}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MultiPost
                userPosts={user.UserPosts}
                screen="user"
                loadMore={() => loadMore()}
                loading={loading}
                hasMore={hasMore}></MultiPost>
            </div>
          </div>
        )}
      </div>
      {user !== null && (
        <Modal
          title={t("BUModal")}
          open={openModal}
          CloseModal={() => setOpenModal(false)}>
          <div className="d-flex justify-content-center">
            <button className="btn btn-danger me-2" onClick={() => banUser()}>
              {t("Ban")}
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setOpenModal(false)}>
              {t("Cancel")}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default connect(mapStateToProps)(UserScreen);
