import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import classNames from "classnames";
import i18next from "i18next";
import "bootstrap/dist/js/bootstrap.js";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setShowSidebar: (showSidebar: boolean) =>
      dispatch({ type: "UPDATE_SHOWSIDEBAR", payload: showSidebar }),
    updateLoggedIn: () =>
      dispatch({ type: "UPDATE_LOGGEDIN", username: "", token: "", role: 0 }),
  };
};

const SideNavBar = (props: any) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage =
    languages.find((l) => l.code === currentLanguageCode) || languages[0];
  const { t } = useTranslation();

  const { nav } = props.state.navigation;
  const setShowSidebar = props.setShowSidebar;
  const { showSidebar } = props.state.navigation;
  const navigate = useNavigate();

  const logout = () => {
    props.updateLoggedIn();
    sessionStorage.clear();
    navigate("/");
  };

  const navigateTo = (link: string) => {
    navigate(link);
    setShowSidebar(false);
  };

  useEffect(() => {
    document
      .querySelector("#outlet")
      ?.setAttribute("dir", currentLanguage.dir || "ltr");
  }, [currentLanguage, t]);

  return (
    <>
      <div
        className={`backdrop ${showSidebar ? "show" : ""}`}
        onClick={() => setShowSidebar(false)}
      />

      <div>
        <aside
          className={`sidebar asm-bg-color-primary ${
            showSidebar ? "show" : ""
          }`}>
          <div className="d-flex justify-content-between border-bottom pb-2">
            <div className="text-white fs-3 ms-3">
              <span className="me-3">{props.state.username}</span>
              <i
                className="bi bi-gear-fill cursor-pointer"
                onClick={() => {
                  navigate("/Dashboard/Settings");
                  setShowSidebar(false);
                }}></i>
            </div>
            <i
              className="bi bi-x fs-3 text-white cursor-pointer me-3"
              onClick={() => setShowSidebar(false)}></i>
          </div>
          <ul className="list-group">
            {nav.map((item: any) => {
              if (item.roles.includes(props.state.role)) {
                return (
                  <li
                    className="list-group-item asm-bg-color-primary border-0 text-white fs-4 cursor-pointer"
                    key={item.id}
                    onClick={() => navigateTo(item.link)}>
                    <i className={`bi ${item.icon} me-3`}></i>
                    {t(item.name)}
                  </li>
                );
              }
            })}
            {/* <li
            className="list-group-item asm-bg-color-primary border-0 text-white fs-4 cursor-pointer"
            onClick={() => navigate("./AllPosts")}>
            <i className="bi bi-house-door-fill me-3"></i>Home
          </li> */}
          </ul>
        </aside>
      </div>
      <nav
        className="navbar navbar-expand-lg navbar-light asm-bg-color-primary"
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          left: 0,
          zIndex: 99,
        }}>
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <i
              className="bi bi-list fs-3 text-white cursor-pointer"
              onClick={() => setShowSidebar(true)}></i>
            <div className="dropdown">
              <i
                className="px-3  dropdown-toggle bi bi-globe2 cursor-pointer text-white fs-4"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {" "}
              </i>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1">
                <li>
                  <span className="dropdown-item-text">{t("language")}</span>
                </li>
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <button
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}>
                      <span
                        className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}></span>
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="d-flex align-items-center language-select-root">
            <button className="btn btn-outline-light" onClick={logout}>
              {t("Logout")}
            </button>
          </div>
        </div>
        <div className="navbar-title text-white">ASM</div>
      </nav>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
