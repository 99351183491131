import React from "react";
import moment from "moment";

export default function TicketCard(props: any) {
  const ticket = props.ticket;

  return (
    <div className="card border-0 shadow mt-3" onClick={props.openModal}>
      <div className="d-flex justify-content-between">
        <div className="d-flex p-2 align-items-center">
          <div>
            <img
              src={ticket.ProfilePicture}
              alt="..."
              className="rounded-circle m-2 postImages cursor-pointer"
            />
          </div>
          <div>
            <h6>{ticket.UserName}</h6>
            <div className="text-size-sm">{ticket.Message}</div>
          </div>
        </div>
        <div className="d-flex align-items-center mx-2 text-size-sm">
          {moment(ticket.DateCreated).format("YY/MM/DD - hh:mm a")}
        </div>
      </div>
    </div>
  );
}
