import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "../modal/Modal";

import { fetchApi } from "../../utils/Connections";

import UserCard from "../user-card/UserCard";
import Spinner from "../spinner/Spinner";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setOnPostScreen: (value: string) =>
      dispatch({ type: "UPDATE_ONPOSTSCREEN", payload: value }),
    setSelectedPost: (value: any) =>
      dispatch({ type: "UPDATE_SELECTEDPOST", payload: value }),
    updatePosts: (value: any) =>
      dispatch({ type: "UPDATE_POSTS", payload: value }),
  };
};

const Post = (prop: any) => {
  const { t } = useTranslation();
  const state = prop;
  let offset = 0;

  const [post, setPost] = useState(state.post);
  const [pathPostScreen, setOnPostScreen] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [users, setUsers] = useState<[]>([]);
  let _type = "";
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const setSelectedPost = state.setSelectedPost;

  const toggleModal = async (type: string) => {
    setOpen(true);
    setLoading(true);
    if (type !== _type) {
      setModalTitle(type);
      offset = 0;
      _type = type;
      setUsers([]);
    }
    const result = await fetchApi(
      `Admins/GetPostDetails?Offset=${offset}&Type=${type}&IdPost=${post.IdPost}`,
      "GET",
      {}
    );
    if (result.StatusCode === 200) {
      let u;
      if (users.length > 0) u = result.My_Result;
      else u = [...users, ...result.My_Result];
      setUsers(u);
    } else {
      alert("Something went wrong");
      setOpen(false);
    }
    setLoading(false);
  };
  const navigate = useNavigate();
  const path = window.location.pathname;

  useEffect(() => {
    if (path === "/Dashboard/Post") {
      setOnPostScreen(true);
    } else {
      setOnPostScreen(false);
    }
  }, [path, setOnPostScreen]);

  useEffect(() => {
    if (state.Post !== undefined) setPost(state.post);
  }, [state.post]);

  const navigateToPost = () => {
    if (pathPostScreen) {
      return;
    } else {
      setSelectedPost(post);
      sessionStorage.setItem("selectedPost", JSON.stringify(post));
      navigate(`/Dashboard/Post?id=${post.IdPost}`);
    }
  };

  const deletePost = async () => {
    setLoading(true);
    const result = await fetchApi(`Admins/DeletePost`, "DELETE", {
      IdPost: post.IdPost,
    });
    if (result.StatusCode === 200) {
      setDeleteModal(false);
      navigate("/Dashboard/AllPosts");
      let posts = [...state.state.posts];
      let index = posts.findIndex((p: any) => p.IdPost === post.IdPost);
      posts.splice(index, 1);
      state.updatePosts(posts);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <div
      className={post.Reported ? "border border-danger rounded p-2 my-1" : ""}>
      <div className="d-flex justify-content-between" key="post-header">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate(`/Dashboard/User?id=${post.IdUser}`)}>
          <img
            src={post.ProfilePicture}
            alt=""
            className="rounded-circle my-2 mx-1 postImages"></img>
          <span className="fw-bold">{post.UserName}</span>
        </div>
        <div className="d-flex align-items-center me-2">
          <i
            className="bi bi-trash cursor-pointer text-danger"
            onClick={() => setDeleteModal(true)}></i>
        </div>
      </div>
      <div
        className={`${pathPostScreen ? "" : "cursor-pointer"} text-size-lg ${
          !pathPostScreen && "postText"
        }`}
        onClick={() => {
          navigateToPost();
        }}>
        {post.Text}
      </div>
      <div className="mt-4">
        {moment(post.DateCreated).format("YY/MM/DD - hh:mm a")}
      </div>
      <div className="d-flex justify-content-between mt-2">
        <i
          className="bi bi-hand-thumbs-up cursor-pointer"
          onClick={() => toggleModal("Likes")}>
          <span className="px-1 text-size-sm">{post.LikeCount}</span>
        </i>
        <i
          className="bi bi-hand-thumbs-down cursor-pointer"
          onClick={() => toggleModal("Dislikes")}>
          <span className="px-1 text-size-sm">{post.DislikeCount}</span>
        </i>
        <i
          className="bi bi-chat cursor-pointer"
          onClick={() => navigateToPost()}>
          <span className="px-1 text-size-sm">{post.CommentCount}</span>
        </i>
        <i
          className="bi bi-bookmark cursor-pointer"
          onClick={() => toggleModal("Saved")}>
          <span className="px-1 text-size-sm">{post.SaveCount}</span>
        </i>
        <i className="bi bi-upload">
          <span className="px-1 text-size-sm">{post.NumberOfShares}</span>
        </i>
      </div>

      <Modal
        title={t(modaltitle)}
        open={open}
        CloseModal={() => setOpen(false)}>
        {loading && <Spinner />}
        <div className="content px-3">
          {users.map((user: any) => (
            <div key={user.IdUser} className="mt-3">
              <UserCard _user={user} showBan={false}></UserCard>
            </div>
          ))}
        </div>
        {users.length === 0 && !loading && (
          <div className="text-center text-muted">
            {t(
              modaltitle === "Likes"
                ? "No Likes"
                : modaltitle === "Dislikes"
                ? "No Dislikes"
                : "No Saves"
            )}
          </div>
        )}
        {users.length % 24 === 0 && users.length !== 0 && !loading && (
          <button
            className="btn asm-btn-bg-color-primary text-white mt-3 w-100"
            onClick={() => toggleModal(_type)}>
            {t("LoadMore")}
          </button>
        )}
      </Modal>
      <Modal
        title={t("DPModal")}
        open={deleteModal}
        CloseModal={() => setDeleteModal(false)}>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="text-mute text-size-sm text-center ">
            {t("DeleteMSG")}
          </div>
          {loading && <Spinner />}

          <button
            className="btn asm-btn-bg-color-primary text-white mt-3"
            onClick={() => deletePost()}>
            {t("Delete")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
