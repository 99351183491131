import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { fetchApi } from "../../utils/Connections";

const mapStateToProps = (state: any) => ({ state: state });

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateOpen: (open: boolean) =>
      dispatch({ type: "UPDATE_ADMINOPEN", payload: open }),
    updateAdmins: (admins: any) =>
      dispatch({ type: "UPDATE_ADMINS", payload: admins }),
    resetAdmins: () => dispatch({ type: "RESET_ADMINS" }),
  };
};

const AddModerators = (props: any) => {
  const { t } = useTranslation();
  const { state } = props;
  const open = state.addAdminOpen;
  const setOpen = props.updateOpen;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<number>(0);
  const [stateChange, setStateChange] = useState(false);
  const [password, setPassword] = useState("");

  const saveChanges = (state: boolean) => {
    if (name === "" || email === "" || role === 0) {
      alert("Please fill all fields");
      return;
    }
    if (state) {
      updateAdmin();
    } else {
      addAdmin();
    }
  };

  const updateAdmin = async () => {
    let id = sessionStorage.getItem("id");
    const body = {
      UserName: name,
      Password: password !== "" ? password : null,
      OldPassword: null,
      IdAdminRole: role,
      IdAdmin: state.selectedAdmin.IdAdmin,
      IdUpdatingAdmin: id,
    };
    const result = await fetchApi(`admins/UpdateAdmin`, "PUT", body);
    if (result.StatusCode === 200) {
      props.resetAdmins();
    } else {
      alert("Something went wrong");
    }
    setOpen(false);
  };

  const addAdmin = async () => {
    let id = sessionStorage.getItem("id");
    const body = {
      UserName: name,
      Email: email,
      IdAdminRole: role,
      IdAdmin: id,
    };
    const result = await fetchApi(`admins/InsertAdmin`, "POST", body);
    if (result.StatusCode === 200) {
      alert("Admin added successfully");
      props.resetAdmins();
    } else {
      alert("Something went wrong");
    }
    setOpen(false);
  };

  useEffect(() => {
    if (state.selectedAdmin !== undefined) {
      setName(state.selectedAdmin.UserName);
      setEmail(state.selectedAdmin.Email);
      setRole(state.selectedAdmin.IdAdminRole);
      if (state.selectedAdmin.id !== -1) {
        setStateChange(true);
      }
    } else {
      setName("");
      setEmail("");
      setRole(0);
      setStateChange(false);
    }
  }, [state.adminToEdit]);

  return (
    <>
      <div
        className={`backdrop ${open ? "show" : ""}`}
        onClick={() => setOpen(!open)}></div>
      <div className={`adminSidebar pt-3 ${open ? "show" : ""}`}>
        <div className="px-3">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">{t("AddAdmin")}</div>
              <div>
                <i
                  className="bi bi-x-lg cursor-pointer"
                  onClick={() => setOpen(!open)}></i>
              </div>
            </div>
            <div className="mt-3">
              <div className="form-group">
                <label className="py-1 px-2  mt-1" htmlFor="name">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="name"
                  placeholder={t("Name")}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              {!stateChange && (
                <div className="form-group">
                  <label className="py-1 px-2  mt-1" htmlFor="email">
                    {t("Email")}
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="email"
                    placeholder={t("Email")}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              {stateChange && state.role === 1 && (
                <div className="form-group">
                  <label className="py-1 px-2  mt-1" htmlFor="email">
                    {t("Password")}
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="password"
                    placeholder={t("Password")}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              )}
              <div className="form-group">
                <label className="py-1 px-2  mt-1" htmlFor="role">
                  {t("Role")}
                </label>
                <select
                  onChange={(e) => setRole(parseInt(e.target.value))}
                  className="form-control "
                  id="role"
                  placeholder="Enter role">
                  <option value={0} selected={role === 0 ? true : false}>
                    {t("SelectRole")}
                  </option>
                  {state.role === 1 && (
                    <option value={2} selected={role === 2 ? true : false}>
                      {t("Admin")}
                    </option>
                  )}
                  <option value={3} selected={role === 3 ? true : false}>
                    {t("Moderator")}
                  </option>
                  <option value={4} selected={role === 4 ? true : false}>
                    {t("Support")}
                  </option>
                </select>
              </div>

              <div className="d-flex justify-content-between">
                <button
                  className="btn asm-btn-bg-color-primary text-white mt-3 w-100 me-2"
                  onClick={() => {
                    saveChanges(stateChange);
                  }}>
                  {t("Save")}
                </button>
                {/* {state.selectedAdmin === undefined ? null : (
                  <button className="btn asm-btn-bg-color-primary text-white mt-3 w-100">
                    {t("Delete")}
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddModerators);
